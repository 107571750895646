<template>
  <div id="report">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-4">
          Devices Log
        </strong>
        <hr>
        <FormLog
        :userData="userData"
        :loaded="loaded"
        :branchs="branchs"
        :nodes="nodes"
        :devices="devices"
        @get_node="get_node"
        @get_device="get_device"
        ></FormLog>
      </div>
    </div>
  </div>
</template>

<script>
import FormLog from '@/components/Form/Log'

export default {
  name: 'MemberLog',
  components:{ FormLog },
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
  },
  mounted(){
    if(!this.loaded.get_branch){
      this.$emit('get_branch');
    }
  },
  methods:{
    get_node(n_id){
      this.$emit('get_node',n_id)
    },
    get_device(d_id){
      this.$emit('get_device',d_id)
    }
  }
}
</script>
